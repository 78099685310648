import Axios from 'axios'

export const configureAxios = () => {
  Axios.defaults.baseURL = process.env.API_URL
}

export const stringifyError = (error: any): string => {
  return `Impossible d'accèder à l'API.
  ${error.status} : ${error.message}
  ${error.response && JSON.stringify(error.response.data)}`
}
