import React, { FunctionComponent, useContext } from 'react'

import { StaticMap } from 'react-map-gl'
import ThemeContext from '@contexts/theme_context'

const ModalMapBackground: FunctionComponent = () => {
  const theme = useContext(ThemeContext)

  return (
    <StaticMap
      width="100vw"
      height="100vh"
      attributionControl={false}
      mapStyle={theme.mapStyle}
      mapboxApiAccessToken={process.env.MAPBOX_API_ACCESS_TOKEN}
      preventStyleDiffing={true}
    />
  )
}

export default ModalMapBackground
