import React, { FunctionComponent, ReactNode, useState } from 'react'

import Replay from '@view_models/replay'

const ReplayContext = React.createContext<Replay>({} as Replay)

interface Props {
  children: ReactNode
  replay: Replay
}

export const ReplayProvider: FunctionComponent<Props> = (props: Props) => {
  const [replay] = useState<Replay>(props.replay)

  return (
    <ReplayContext.Provider value={replay}>
      {props.children}
    </ReplayContext.Provider>
  )
}

export default ReplayContext
