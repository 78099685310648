import { BoatMarker } from '@view_models/boat'
import { IControlsProvider } from '@contexts/controls_context'
import { RGBAColor } from 'deck.gl'
import { TextLayer } from '@deck.gl/layers'

interface Props {
  eachBoatLatestMarker: BoatMarker[]
  controls: IControlsProvider
  selectedBoats: number[]
  textColor: RGBAColor
}

const BoatTagLayer = (props: Props) => {
  const { eachBoatLatestMarker, controls, selectedBoats, textColor } = props

  const getSize = (bm: BoatMarker): number => {
    const boatMarkerIsSelected =
      selectedBoats.length === 0 || selectedBoats.includes(bm.shipNum)
    return boatMarkerIsSelected ? 13 : 0
  }

  return new TextLayer<BoatMarker>({
    id: 'boat-tag-layer',
    data: eachBoatLatestMarker,
    pickable: false,
    getPosition: (bm) => bm.coordinates,
    getText: (bm) => bm.name,
    getColor: textColor,
    getSize: getSize,
    getAngle: 0,
    fontFamily: 'Roboto Mono, Monaco, monospace',
    fontWeight: 400,
    lineHeight: 1.75,
    getPixelOffset: [10, 20],
    getTextAnchor: 'start',
    getAlignmentBaseline: 'center',
    updateTriggers: {
      getSize: [selectedBoats],
    },
    visible: controls.boatNameIsVisible,
  })
}

export default BoatTagLayer
