import { RGBAColor, RGBColor } from '@deck.gl/core/utils/color'

export function hexToRgbArray(hexColor: string): RGBColor {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
  if (result) {
    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ]
  } else {
    throw new SyntaxError(
      `Expected color in hexadecimal format (e.g. #12AB12), but got ${hexColor}.`,
    )
  }
}

export const TRAIL_COLORS = [
  '#FD6319',
  '#12939A',
  '#DDB27C',
  '#88572C',
  '#FF991F',
  '#223F9A',
  '#DA70BF',
  '#125C77',
  '#4DC19C',
  '#776E57',
  '#17B8BE',
  '#F6D18A',
  '#B7885E',
  '#FFCB99',
  '#F89570',
  '#829AE3',
  '#E79FD5',
  '#1E96BE',
  '#89DAC1',
  '#B3AD9E',
]

export const getColorArrayFromId = (id: number): RGBColor =>
  hexToRgbArray(TRAIL_COLORS[id % TRAIL_COLORS.length])

export const getColorFromId = (id: number): string =>
  TRAIL_COLORS[id % TRAIL_COLORS.length]

export const GHOST_TRAIL_COLOR = [30, 30, 30, 110] as RGBAColor

export const DARK_MODE_BOAT_TAG_COLOR = [255, 255, 255, 220] as RGBAColor
export const LIGHT_MODE_BOAT_TAG_COLOR = [0, 0, 0, 220] as RGBAColor

export const COLORS = {
  primary: '#3a9ebf',
  secondary: '#cce310',
  primaryDark: '#1d2350',
}
