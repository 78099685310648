import Boat, { MarkerInfo } from '@view_models/boat'

import { DateTime } from 'luxon'
import { MarkerInfoDTO } from './boat_dto'
import RectangleCoordinates from '@view_models/rectangle_coordinates'
import Replay from '@view_models/replay'

export default interface OutingDTO {
  ship_num: number
  name: string
  subtitle: string
  skipper_name: string
  link: string
  view_state: RectangleCoordinates | null
  path: [number, number][]
  timestamps: number[]
  marker_info: MarkerInfoDTO[]
}

export const outingToBoat = (outing: OutingDTO): Boat => {
  return new Boat({
    ...outing,
    boatLink: outing.link,
    skipperName: outing.skipper_name,
    markerInfo: outing.marker_info.map((marker) =>
      MarkerInfo.fromMarkerInfoDTO(marker),
    ),
    shipNum: 0,
  })
}

export const outingToReplay = (outing: OutingDTO, boatUuid: string): Replay => {
  const viewState = outing.view_state
    ? outing.view_state
    : ([
        outing.path[0],
        outing.path[outing.path.length - 1],
      ] as RectangleCoordinates)
  const lastTimestamp = DateTime.fromSeconds(
    outing.timestamps[outing.timestamps.length - 1],
  )
  const firstTimestamp = DateTime.fromSeconds(outing.timestamps[0])

  const numberOfDaysInTracking: string = Number.isNaN(
    Math.round(Math.abs(firstTimestamp.diffNow('days').toObject().days ?? NaN)),
  )
    ? ''
    : `${Math.round(
        Math.abs(firstTimestamp.diffNow('days').toObject().days ?? NaN),
      )}`

  // const subtitle =
  return new Replay({
    id: 0,
    uuid: boatUuid,
    startTime: firstTimestamp,
    endTime: lastTimestamp,
    name: '',
    banner: outing?.name ? outing.name : null,
    viewState: viewState,
    title: outing?.name ? outing.name : null,
    subtitle: outing?.subtitle
      ? outing.subtitle
      : `Positions sur les ${numberOfDaysInTracking} dernier(s) jour(s)`,
    link: outing.link,
  })
}
