import React, { FunctionComponent, useContext } from 'react'

import HoverViewModel from '@view_models/hover'
import ThemeContext from '@contexts/theme_context'
import { Typography } from '@material-ui/core'

interface HoverProps {
  isVisible: boolean
  hoverInfo: HoverViewModel
}

const Hover: FunctionComponent<HoverProps> = (props: HoverProps) => {
  const theme = useContext(ThemeContext)

  if (props.isVisible) {
    return (
      <Typography
        variant="button"
        style={{
          position: 'absolute',
          zIndex: 1,
          pointerEvents: 'none',
          left: props.hoverInfo.x + 5,
          top: props.hoverInfo.y + 5,
          color: theme.isInDarkMode ? '#fff' : '#000',
        }}
      >
        {props.hoverInfo.body || 'Unknown'}
      </Typography>
    )
  } else {
    return null
  }
}

export default Hover
