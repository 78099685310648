import Replay from '@view_models/replay'
import ReplayDTO from '@dto/replay_dto'
import axios from 'axios'
import { stringifyError } from '@utils/axios'

export default class RaceRepository {
  static get(uuid: string): Promise<Replay> {
    return new Promise<Replay>((resolve, reject) => {
      axios
        .get<ReplayDTO[]>(`pos_maps_races?uuid=eq.${uuid}`)
        .then((request) => {
          const httpIsError = request.status >= 400
          if (httpIsError) {
            return reject(`Impossible d'accèder à l'API.
${request.status} : ${request.statusText}`)
          }

          const raceExists = request.data.length > 0
          if (raceExists) {
            const race = Replay.fromReplayDTO(request.data[0])
            const raceHasAViewState = race.viewState != undefined
            if (raceHasAViewState) {
              resolve(race)
            } else {
              reject(`Race has no view_state. uuid : ${uuid}`)
            }
          } else {
            reject(`No race found with uuid : ${uuid}`)
          }
        })
        .catch((error) => reject(stringifyError(error)))
    })
  }
}
