import { outingToBoat, outingToReplay } from '@dto/outing_dto'

import Boat from '@view_models/boat'
import OutingRepository from '@repositories/outing_repository'
import Replay from '@view_models/replay'

const getOuting = (
  boatUuid: string,
): Promise<{ replay: Replay; boats: Boat[] }> => {
  return new Promise<{ replay: Replay; boats: Boat[] }>((resolve, reject) => {
    const result: { replay: Replay; boats: Boat[] } = {} as {
      replay: Replay
      boats: Boat[]
    }

    OutingRepository.get(boatUuid)
      .then((outing) => {
        result.replay = outingToReplay(outing, boatUuid)
        result.boats = [outingToBoat(outing)]
        window.document.title = outing.name
        resolve(result)
      })
      .catch((error) => reject(error))
  })
}

export default getOuting
