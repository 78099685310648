import React, { FunctionComponent, ReactNode, useState } from 'react'

import Error from '@components/error'

const ErrorContext = React.createContext<IErrorProvider>({} as IErrorProvider)

export interface IErrorProvider {
  setError: (error: string) => void
}

interface Props {
  children: ReactNode
}

export const ErrorProvider: FunctionComponent<Props> = (props) => {
  const [error, setError] = useState<string | null>(null)

  if (error === null) {
    return (
      <ErrorContext.Provider value={{ setError: setError }}>
        {props.children}
      </ErrorContext.Provider>
    )
  } else {
    return <Error errorMessage={error} />
  }
}

export default ErrorContext
