import Boat from '@view_models/boat'
import BoatsRepository from '@repositories/boats_repository'
import RaceRepository from '@repositories/race_repository'
import Replay from '@view_models/replay'

const getRace = (
  raceUuid: string,
): Promise<{ replay: Replay; boats: Boat[] }> => {
  return new Promise<{ replay: Replay; boats: Boat[] }>((resolve, reject) => {
    RaceRepository.get(raceUuid)
      .then((replayRace) => {
        window.document.title = replayRace.title ?? 'SAIL.cloud'

        BoatsRepository.get(replayRace.id)
          .then((fetchedBoats) => {
            resolve({ replay: replayRace, boats: fetchedBoats })
          })
          .catch((error) => reject(error))
      })
      .catch((error) => reject(error))
  })
}

export default getRace
