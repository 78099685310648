import React, { FunctionComponent, useContext } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { COLORS } from '@utils/colors'
import ControlsContext from '@contexts/controls_context'
import OptionDrawer from '@components/option_drawer'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import TimelineSlider from './timeline_slider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timelineControlsGroup: {
      position: 'absolute',
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 20,
      bottom: 20,
    },
    playbackButton: {
      backgroundColor: COLORS.primary,
      padding: 3,
      marginRight: 15,
      boxShadow: theme.shadows[6],
      borderRadius: '50%',
      display: 'flex',
    },
  }),
)

const Controls: FunctionComponent = () => {
  const controls = useContext(ControlsContext)

  const classes = useStyles()

  return (
    <div>
      <div className={classes.timelineControlsGroup}>
        <div
          onClick={controls.togglePlayback}
          className={classes.playbackButton}
        >
          {controls.playback ? (
            <PauseIcon fontSize="large" htmlColor="#fff" />
          ) : (
            <PlayArrowIcon fontSize="large" htmlColor="#fff" />
          )}
        </div>
        <TimelineSlider
          min={controls.visibleTimelineStart}
          max={controls.visibleTimelineEnd}
          value={controls.currentTime}
          onChange={(value) => {
            controls.setIsDraggingTimeline(true)
            controls.setCurrentTime(value)
          }}
          onChangeCommitted={() => controls.setIsDraggingTimeline(false)}
        />
      </div>
      <OptionDrawer
        isDrawerOpen={controls.drawerIsOpen}
        closeDrawer={controls.closeDrawer}
      />
    </div>
  )
}

export default Controls
