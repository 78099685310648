import { BoatMarker, boatMarkersAreEquals } from '@view_models/boat'
import { PickInfo, RGBAColor } from 'deck.gl'

import { IControlsProvider } from '@contexts/controls_context'
import { IconLayer } from '@deck.gl/layers'
import LOCATION_MARKER from '@assets/marker.png'
import { ViewStateObject } from '@view_models/view_state'
import { getColorArrayFromId } from '@utils/colors'

const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 256, height: 256, mask: true },
  markerBorder: { x: 256, y: 0, width: 256, height: 256, mask: false },
  markerNoCog: { x: 0, y: 256, width: 256, height: 256, mask: true },
  markerBorderNoCog: { x: 256, y: 256, width: 256, height: 256, mask: false },
}

const SELECTED_MARKER_SIZE = 15
const HOVERED_MARKER_SIZE = 12
const getMarkerSizeFromZoom = (zoom: number) => ((zoom - 1) / 17.5) ** 20 + 4

interface Props {
  locationMarkerData: BoatMarker[]
  controls: IControlsProvider
  boatsMarker: number[]
  boatsMarkerDetail: BoatMarker[]
  hoveredBoatMarker: PickInfo<BoatMarker> | null
  viewState: ViewStateObject
  onHover: (info: PickInfo<BoatMarker>) => void
  onClick: (info: PickInfo<BoatMarker>) => void
}

const BoatMarkersLayer = (props: Props) => {
  const {
    locationMarkerData,
    controls,
    boatsMarker,
    boatsMarkerDetail,
    hoveredBoatMarker,
    viewState,
    onHover,
    onClick,
  } = props

  const getColor = (bm: BoatMarker): RGBAColor => {
    const markerIsSelected =
      boatsMarker.length > 0 &&
      boatsMarkerDetail.filter((marker) => boatMarkersAreEquals(marker, bm))
        .length > 0
    const markerIsHovered =
      hoveredBoatMarker && boatMarkersAreEquals(hoveredBoatMarker.object, bm)
    const colorShouldBeSolid = markerIsSelected || markerIsHovered
    const colorTransparency = colorShouldBeSolid
      ? 255
      : ((viewState.zoom - 1) / 10.9) ** 10
    return [...getColorArrayFromId(bm.shipNum), colorTransparency]
  }

  const getSize = (bm: BoatMarker): number => {
    const markerIsSelected =
      boatsMarker.length > 0 &&
      boatsMarkerDetail.filter((marker) => boatMarkersAreEquals(marker, bm))
        .length > 0
    const markerIsHovered =
      hoveredBoatMarker && boatMarkersAreEquals(hoveredBoatMarker.object, bm)
    if (markerIsSelected) {
      return SELECTED_MARKER_SIZE
    } else if (markerIsHovered) {
      return HOVERED_MARKER_SIZE
    } else {
      return getMarkerSizeFromZoom(viewState.zoom)
    }
  }

  const getIcon = (bm: BoatMarker): string => {
    const markerIsSelected =
      boatsMarker.length > 0 &&
      boatsMarkerDetail.filter((marker) => boatMarkersAreEquals(marker, bm))
        .length > 0
    const markerCogExists = bm.markerInfo.cog
    if (markerIsSelected && markerCogExists) {
      return 'marker'
    } else {
      return 'markerNoCog'
    }
  }

  return new IconLayer<BoatMarker>({
    id: 'boat-marker-layer',
    data: locationMarkerData,
    pickable: true,
    iconAtlas: LOCATION_MARKER,
    iconMapping: ICON_MAPPING,
    getIcon: getIcon,
    getSize: getSize,
    sizeUnits: 'pixels',
    getAngle: (bm) => 360 - bm.markerInfo.cog!,
    getPosition: (bm) => bm.coordinates,
    getColor: getColor,
    updateTriggers: {
      getSize: [viewState.zoom, hoveredBoatMarker, boatsMarker],
      getColor: [viewState.zoom, hoveredBoatMarker, boatsMarker],
    },
    onHover: onHover,
    onClick: onClick,
    visible: controls.locationMarkerAreVisible,
  })
}

export default BoatMarkersLayer
