import Boat, { BoatMarker, BoatPath } from '@view_models/boat'

import { DateTime } from 'luxon'
import { PickInfo } from 'deck.gl'

export default class HoverViewModel {
  x: number
  y: number
  body: string

  constructor(x: number, y: number, body: string) {
    this.x = x
    this.y = y
    this.body = body
  }

  static fromBoatPickInfo(
    boatPickInfo: PickInfo<Boat> | PickInfo<BoatPath>,
  ): HoverViewModel | null {
    return boatPickInfo.object
      ? new HoverViewModel(
          boatPickInfo.x,
          boatPickInfo.y,
          boatPickInfo.object.name,
        )
      : null
  }

  static fromBoatMarkerPickInfo(
    boatMarker: PickInfo<BoatMarker>,
  ): HoverViewModel | null {
    const boatMarkerExists: boolean = boatMarker.object != null

    if (boatMarkerExists) {
      const trackerType = boatMarker.object.markerInfo.trackerType
        ? ` - ${boatMarker.object.markerInfo.trackerType}`
        : ''
      const datetime = ` @ ${DateTime.fromSeconds(
        boatMarker.object.timestamp,
      ).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}`

      const body = `${boatMarker.object.name}${datetime}${trackerType}`

      return new HoverViewModel(boatMarker.x, boatMarker.y, body)
    } else {
      return null
    }
  }
}
