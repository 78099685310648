import BoatDTO, { MarkerInfoDTO } from '@dto/boat_dto'

export class MarkerInfo {
  cog: number | null
  sog: number
  trackerType: string
  sogMax: number
  sogAvg: number
  trip: number
  twa: number
  twd: number
  tws: number
  twsMax: number

  constructor(props: {
    cog: number | null
    sog: number
    trackerType: string
    sogMax: number
    sogAvg: number
    trip: number
    twa: number
    twd: number
    tws: number
    twsMax: number
  }) {
    const {
      cog,
      sog,
      trackerType,
      sogMax,
      sogAvg,
      trip,
      twa,
      twd,
      tws,
      twsMax,
    } = props
    this.cog = cog
    this.sog = sog
    this.trackerType = trackerType
    this.sogMax = sogMax
    this.sogAvg = sogAvg
    this.trip = trip
    this.twa = twa
    this.twd = twd
    this.tws = tws
    this.twsMax = twsMax
  }

  static fromMarkerInfoDTO(markerInfoDto: MarkerInfoDTO): MarkerInfo {
    return new MarkerInfo({
      ...markerInfoDto,
      trackerType: markerInfoDto.tracker_type,
      sogAvg: markerInfoDto.sog_avg,
      sogMax: markerInfoDto.sog_max,
      twsMax: markerInfoDto.tws_max,
    })
  }
}

export default class Boat {
  shipNum: number
  name: string
  skipperName: string
  boatLink: string | null
  path: [number, number][]
  timestamps: number[]
  markerInfo: MarkerInfo[]

  constructor(props: {
    shipNum: number
    name: string
    skipperName: string
    boatLink: string | null
    path: [number, number][]
    timestamps: number[]
    markerInfo: MarkerInfo[]
  }) {
    const {
      shipNum,
      name,
      skipperName,
      boatLink,
      path,
      timestamps,
      markerInfo,
    } = props

    this.shipNum = shipNum
    this.name = name
    this.skipperName = skipperName
    this.boatLink = boatLink
    this.path = path
    this.timestamps = timestamps
    this.markerInfo = markerInfo
  }

  static fromBoatDTO(boatData: BoatDTO) {
    return new Boat({
      ...boatData,
      skipperName: boatData.skipper_name,
      shipNum: boatData.ship_num,
      boatLink: boatData.boat_link,
      markerInfo: boatData.marker_info.map((marker) =>
        MarkerInfo.fromMarkerInfoDTO(marker),
      ),
    })
  }

  toBoatPath(currentTime: number): BoatPath {
    return {
      shipNum: this.shipNum,
      name: this.name,
      path: this.path.slice(
        0,
        this.timestamps.filter((t) => t < currentTime).length,
      ),
    }
  }

  toBoatMarkers(): BoatMarker[] {
    return this.timestamps.map((timestamp, index) => {
      return {
        name: this.name,
        skipperName: this.skipperName,
        boatLink: this.boatLink,
        shipNum: this.shipNum,
        timestamp: timestamp,
        coordinates: this.path[index],
        markerInfo: this.markerInfo[index],
      }
    })
  }

  getLastMarker(currentTime: number): BoatMarker {
    const lastTimestampIndex = this.timestamps.reduce(
      (iMax, x, i, arr) => (x > arr[iMax] && x < currentTime ? i : iMax),
      0,
    )
    return {
      name: this.name,
      skipperName: this.skipperName,
      boatLink: this.boatLink,
      shipNum: this.shipNum,
      timestamp: this.timestamps[lastTimestampIndex],
      coordinates: this.path[lastTimestampIndex],
      markerInfo: this.markerInfo[lastTimestampIndex],
    }
  }
}

export interface BoatPath {
  shipNum: number
  name: string
  path: [number, number][]
}

export interface BoatMarker {
  name: string
  skipperName: string
  boatLink: string | null
  shipNum: number
  timestamp: number
  coordinates: [number, number]
  markerInfo: MarkerInfo
}

export const boatMarkersAreEquals = (x: BoatMarker, y: BoatMarker): boolean => {
  return (
    x.coordinates[0] === y.coordinates[0] &&
    x.coordinates[1] === y.coordinates[1] &&
    x.timestamp === y.timestamp &&
    x.shipNum === y.shipNum &&
    x.markerInfo.trackerType === y.markerInfo.trackerType
  )
}
