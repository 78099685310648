import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from 'react'

import { DateTime } from 'luxon'
import ReplayContext from './replay_context'

const ControlsContext = React.createContext<IControlsProvider>(
  {} as IControlsProvider,
)

interface Props {
  children: ReactNode
}

interface State {
  currentTime: number
  trailLength: number
  playback: boolean
  playbackSpeed: number
  isDraggingTimeline: boolean
  drawerIsOpen: boolean
  seaMarksAreVisible: boolean
  locationMarkerAreVisible: boolean
  boatPathIsVisible: boolean
  boatNameIsVisible: boolean
  visibleTimelineStart: number
  visibleTimelineEnd: number
}

export interface IControlsProvider extends State {
  setCurrentTime: (time: number) => void
  togglePlayback: () => void
  setTrailLength: (trailLength: number) => void
  setPlaybackSpeed: (playbackSpeed: number) => void
  setIsDraggingTimeline: (isDragging: boolean) => void
  openDrawer: () => void
  closeDrawer: () => void
  toggleDrawer: () => void
  toggleSeaMarks: () => void
  toggleLocationMarker: () => void
  toggleBoatPath: () => void
  toggleBoatName: () => void
  setVisibleTimelineStart: (start: number) => void
  setVisibleTimelineEnd: (end: number) => void
}

export const ControlsProvider: FunctionComponent<Props> = (props: Props) => {
  const replay = useContext(ReplayContext)
  const now = DateTime.now()

  const raceDuration = replay.getReplayDurationSeconds()

  const DEFAULT_PLAYBACK_SPEED = raceDuration / 5000
  const DEFAULT_TRAIL_LENGTH = raceDuration / 4
  const DEFAULT_CURRENT_TIME = replay.endTime < now ? replay.endTime : now

  const [currentTime, setCurrentTime] = useState<number>(
    DEFAULT_CURRENT_TIME.toSeconds(),
  )
  const [trailLength, setTrailLength] = useState<number>(DEFAULT_TRAIL_LENGTH)
  const [playback, setPlayback] = useState<boolean>(false)
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(
    DEFAULT_PLAYBACK_SPEED,
  )
  const [isDraggingTimeline, setIsDraggingTimeline] = useState<boolean>(false)
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false)
  const [seaMarksAreVisible, setSeaMarksAreVisible] = useState<boolean>(true)
  const [locationMarkerAreVisible, setLocationMarkerAreVisible] =
    useState<boolean>(true)
  const [boatPathIsVisible, setBoatPathIsVisible] = useState<boolean>(true)
  const [boatNameIsVisible, setBoatNameIsVisible] = useState<boolean>(true)
  const [visibleTimelineStart, setVisibleTimelineStart] = useState<number>(
    replay.startTime.toSeconds(),
  )
  const [visibleTimelineEnd, setVisibleTimelineEnd] = useState<number>(
    replay.endTime.toSeconds(),
  )

  const togglePlayback = (): void => {
    setPlayback(!playback)
  }

  const openDrawer = (): void => {
    setDrawerIsOpen(true)
  }

  const closeDrawer = (): void => {
    setDrawerIsOpen(false)
  }

  const toggleDrawer = (): void => {
    setDrawerIsOpen(!drawerIsOpen)
  }

  const toggleSeaMarks = (): void => {
    setSeaMarksAreVisible(!seaMarksAreVisible)
  }

  const toggleLocationMarker = (): void => {
    setLocationMarkerAreVisible(!locationMarkerAreVisible)
  }

  const toggleBoatPath = (): void => {
    setBoatPathIsVisible(!boatPathIsVisible)
  }

  const toggleBoatName = (): void => {
    setBoatNameIsVisible(!boatNameIsVisible)
  }

  return (
    <ControlsContext.Provider
      value={{
        playback: playback,
        playbackSpeed: playbackSpeed,
        currentTime: currentTime,
        trailLength: trailLength,
        isDraggingTimeline: isDraggingTimeline,
        drawerIsOpen: drawerIsOpen,
        seaMarksAreVisible: seaMarksAreVisible,
        locationMarkerAreVisible: locationMarkerAreVisible,
        boatPathIsVisible: boatPathIsVisible,
        boatNameIsVisible: boatNameIsVisible,
        visibleTimelineStart: visibleTimelineStart,
        visibleTimelineEnd: visibleTimelineEnd,
        setCurrentTime: setCurrentTime,
        togglePlayback: togglePlayback,
        setTrailLength: setTrailLength,
        setPlaybackSpeed: setPlaybackSpeed,
        setIsDraggingTimeline: setIsDraggingTimeline,
        openDrawer: openDrawer,
        closeDrawer: closeDrawer,
        toggleDrawer: toggleDrawer,
        toggleSeaMarks: toggleSeaMarks,
        toggleLocationMarker: toggleLocationMarker,
        toggleBoatPath: toggleBoatPath,
        toggleBoatName: toggleBoatName,
        setVisibleTimelineStart: setVisibleTimelineStart,
        setVisibleTimelineEnd: setVisibleTimelineEnd,
      }}
    >
      {props.children}
    </ControlsContext.Provider>
  )
}

export default ControlsContext
