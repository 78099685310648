import Boat, { BoatPath } from '@view_models/boat'

import { IControlsProvider } from '@contexts/controls_context'
import { PathLayer } from '@deck.gl/layers'
import { PickInfo } from 'deck.gl'
import { getColor } from '@utils/map_utils'

const DEFAULT_TRAIL_WIDTH = 0.6
const HIDDEN_TRAIL_WIDTH = 0

interface Props {
  controls: IControlsProvider
  boatsData: Boat[]
  selectedBoats: number[]
  notSelectedBoatsTrailAreVisible: boolean
  onClick: (info: PickInfo<BoatPath>) => void
  onHover: (info: PickInfo<BoatPath>) => void
}

const BoatTrailLayer = (props: Props) => {
  const {
    controls,
    boatsData,
    selectedBoats,
    notSelectedBoatsTrailAreVisible,
    onClick,
    onHover,
  } = props

  const getWidth = (bp: BoatPath): number => {
    const noBoatSelected = selectedBoats.length === 0
    const boatIsSelected = selectedBoats.includes(bp.shipNum)
    if (noBoatSelected) {
      return DEFAULT_TRAIL_WIDTH
    } else if (boatIsSelected) {
      return DEFAULT_TRAIL_WIDTH
    } else {
      if (notSelectedBoatsTrailAreVisible) {
        return DEFAULT_TRAIL_WIDTH
      } else {
        return HIDDEN_TRAIL_WIDTH
      }
    }
  }

  return new PathLayer<BoatPath>({
    id: 'boat-trail-layer',
    data: boatsData.map((boat) => boat.toBoatPath(controls.currentTime)),
    pickable: true,
    widthUnits: 'pixels',
    getPath: (b: BoatPath) => b.path,
    getColor: (b: BoatPath) => getColor(b.shipNum, selectedBoats),
    onClick: onClick,
    onHover: onHover,
    getWidth: getWidth,
    opacity: 0.2,
    visible: controls.boatPathIsVisible,
  })
}

export default BoatTrailLayer
