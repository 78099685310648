import Modal, { ModalType } from '@components/modal'
import React, { FunctionComponent, ReactNode, useState } from 'react'

const ModalContext = React.createContext<IModalProvider>({} as IModalProvider)

export interface IModalProvider {
  setModal: (modal: ModalType) => void
  setIsVisible: (shouldBeVisible: boolean) => void
}

interface Props {
  children: ReactNode
}

export const ModalProvider: FunctionComponent<Props> = (props) => {
  const [modal, setModal] = useState<ModalType>({} as ModalType)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  return (
    <ModalContext.Provider
      value={{ setModal: setModal, setIsVisible: setIsVisible }}
    >
      {props.children}
      <Modal modal={modal} isVisible={isVisible} setIsVisible={setIsVisible} />
    </ModalContext.Provider>
  )
}

export default ModalContext
