type RectangleCoordinates = [[number, number], [number, number]]

const isLatitudeInBoudaries = (latitude: number): boolean => {
  return latitude > -90 && latitude < 90
}

const isLongitudeInBoudaries = (longitude: number): boolean => {
  return longitude > -180 && longitude < 180
}

export const isRectangleValid = (rectangle: RectangleCoordinates) => {
  const isNotNullNorUndefined = rectangle != undefined
  const coordsAreNumbers =
    isNotNullNorUndefined &&
    !isNaN(rectangle[0][0]) &&
    !isNaN(rectangle[0][1]) &&
    !isNaN(rectangle[1][0]) &&
    !isNaN(rectangle[1][1])
  return (
    coordsAreNumbers &&
    isLongitudeInBoudaries(rectangle[0][0]) &&
    isLatitudeInBoudaries(rectangle[0][1]) &&
    isLongitudeInBoudaries(rectangle[1][0]) &&
    isLatitudeInBoudaries(rectangle[1][1])
  )
}

export default RectangleCoordinates
