import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@material-ui/core'
import React, { FunctionComponent, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RefreshIcon from '@material-ui/icons/Refresh'

interface ErrorProps {
  errorMessage: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
  }),
)

const Error: FunctionComponent<ErrorProps> = (props: ErrorProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const classes = useStyles()

  return (
    <div className={classes.container}>
      {!isExpanded && (
        <React.Fragment>
          <Typography variant="h1" component="h1" color="error">
            Error
          </Typography>
          <Typography variant="subtitle1" color="error">
            A problem occured while fetching data, please try to reload the
            page.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<RefreshIcon />}
            style={{ marginTop: 20 }}
            // @ts-ignore
            onClick={() => window.location.reload()}
          >
            reload
          </Button>
        </React.Fragment>
      )}
      <Accordion
        style={{ marginTop: 20 }}
        onChange={(_, isExpanded) => setIsExpanded(isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Error Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ whiteSpace: 'pre-wrap' }}>
            {props.errorMessage}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Error
