import RectangleCoordinates from '@view_models/rectangle_coordinates'
import { TOP_BAR_HEIGHT } from '@utils/consts'
import { UndefinedError } from '@errors/default_error'
import { WebMercatorViewport } from '@deck.gl/core'

export interface ViewStateObject {
  longitude: number
  latitude: number
  zoom: number
}

export const viewStatesAreEquals = (
  viewState: ViewStateObject,
  comparative: ViewStateObject,
): boolean => {
  return (
    viewState.longitude === comparative.longitude &&
    viewState.latitude === comparative.latitude &&
    viewState.zoom === comparative.zoom
  )
}

export default class ViewState {
  longitude: number
  latitude: number
  zoom: number

  constructor(longitude: number, latitude: number, zoom: number) {
    if (longitude == undefined || latitude == undefined || zoom == undefined) {
      throw new UndefinedError(
        'Arguments longitude, latitude and zoom must not be undefined.',
      )
    }
    this.longitude = longitude
    this.latitude = latitude
    this.zoom = zoom
  }

  toDeckGlViewState(): ViewStateObject {
    return {
      longitude: this.longitude,
      latitude: this.latitude,
      zoom: this.zoom,
    }
  }

  static fromRectangle(
    rectangleCoordinates: RectangleCoordinates | null,
  ): ViewStateObject {
    let viewState = { longitude: 0, latitude: 0, zoom: 0 }
    if (rectangleCoordinates) {
      const computedViewState = new WebMercatorViewport({
        height: window.innerHeight - TOP_BAR_HEIGHT,
        width: window.innerWidth,
      }).fitBounds(rectangleCoordinates, { padding: 50 })
      viewState = {
        //@ts-ignore
        longitude: computedViewState.longitude,
        //@ts-ignore
        latitude: computedViewState.latitude,
        zoom: computedViewState.zoom,
      }
    }
    return viewState
  }
}
