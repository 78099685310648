import Boat from '@view_models/boat'
import BoatDTO from '@dto/boat_dto'
import axios from 'axios'
import { stringifyError } from '@utils/axios'

const POS_MAPS_REPLAY_URL = 'rpc/pos_maps_replay'

export default class BoatsRepository {
  static get(raceId: number): Promise<Boat[]> {
    return new Promise<Boat[]>((resolve, reject) => {
      axios
        .post<BoatDTO[]>(POS_MAPS_REPLAY_URL, { r_id: raceId })
        .then((request) => {
          const httpIsOk = request.status < 400
          if (httpIsOk) {
            resolve(request.data.map((boatDto) => Boat.fromBoatDTO(boatDto)))
          } else {
            reject(`Impossible d'accèder à l'API.
${request.status} : ${request.statusText}`)
          }
        })
        .catch((error) => reject(stringifyError(error)))
    })
  }
}
