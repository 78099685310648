import RectangleCoordinates, {
  isRectangleValid,
} from '@view_models/rectangle_coordinates'
import ViewState, { ViewStateObject } from '@view_models/view_state'

import { DateTime } from 'luxon'
import RaceDTO from '@dto/replay_dto'

export default class Replay {
  id: number
  uuid: string
  endTime: DateTime
  startTime: DateTime
  name: string
  banner: string | null
  viewState: ViewStateObject
  title: string | null
  subtitle: string | null
  link: string | null

  constructor(props: {
    id: number
    uuid: string
    endTime: DateTime
    startTime: DateTime
    name: string
    banner: string | null
    viewState: RectangleCoordinates | null
    title: string | null
    subtitle: string | null
    link: string | null
  }) {
    const {
      id,
      uuid,
      endTime,
      startTime,
      name,
      banner,
      viewState,
      title,
      subtitle,
      link,
    } = props

    const paramsAreValid =
      !isNaN(id) &&
      typeof uuid == 'string' &&
      endTime instanceof DateTime &&
      startTime instanceof DateTime &&
      typeof name == 'string' &&
      ((viewState && isRectangleValid(viewState)) || viewState === null)

    if (paramsAreValid) {
      this.id = id
      this.uuid = uuid
      this.endTime = endTime > DateTime.now() ? DateTime.now() : endTime
      this.startTime = startTime
      this.name = name
      this.banner = banner
      this.viewState = ViewState.fromRectangle(viewState)
      this.title = title
      this.subtitle = subtitle
      this.link = link
    } else {
      throw new TypeError(`The race parameters are not valid.
  {
    id: ${id},
    uuid: ${uuid},
    endTime: ${endTime},
    startTime: ${startTime},
    name: ${name},
    banner: ${banner},
    viewState: ${viewState},
    title: ${title},
    subtitle: ${subtitle},
  }
      `)
    }
  }

  static fromReplayDTO(raceDto: RaceDTO) {
    return new Replay({
      ...raceDto,
      startTime: DateTime.fromISO(raceDto.start_time),
      endTime: DateTime.fromISO(raceDto.end_time),
      viewState: raceDto.view_state,
    })
  }

  getReplayDurationSeconds(): number {
    return this.endTime.diff(this.startTime).as('seconds')
  }
}
