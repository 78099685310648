import { Button, Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { FunctionComponent, useContext, useState } from 'react'

import { COLORS } from '@utils/colors'
import DoneIcon from '@material-ui/icons/Done'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ThemeContext from '@contexts/theme_context'

const useStyles = makeStyles(() =>
  createStyles({
    button: { marginTop: 20, float: 'right' },
  }),
)

const ShareDialogContent: FunctionComponent = () => {
  const { isInDarkMode } = useContext(ThemeContext)

  const [textHasBeenCopied, setTextHasBeenCopied] = useState<boolean>(false)

  const classes = useStyles()

  return (
    <div>
      <a
        style={
          isInDarkMode
            ? {
                color: COLORS.secondary,
                textDecorationColor: COLORS.secondary,
              }
            : {}
        }
        href={window.location.href}
      >
        <Typography>{window.location.href}</Typography>
      </a>
      <Button
        variant={textHasBeenCopied ? 'outlined' : 'contained'}
        // color="secondary"
        startIcon={textHasBeenCopied ? <DoneIcon /> : <FileCopyIcon />}
        className={classes.button}
        // @ts-ignore
        onClick={() => {
          navigator.clipboard
            .writeText(window.location.href)
            .then(() => setTextHasBeenCopied(true))
        }}
      >
        {textHasBeenCopied ? 'Lien copié !' : 'Copier lien'}
      </Button>
    </div>
  )
}

export default ShareDialogContent
