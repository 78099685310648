import Boat from '@view_models/boat'
import { IControlsProvider } from '@contexts/controls_context'
import { PickInfo } from 'deck.gl'
import Replay from '@view_models/replay'
import { TripsLayer } from '@deck.gl/geo-layers'
import { getColor } from '@utils/map_utils'

const GHOST_TRIP_WIDTH = 2
const DEFAULT_TRIP_WIDTH = 4
const SELECTED_TRIP_WIDTH = 4
const HIDDEN_TRIP_WIDTH = 0
interface Props {
  boatsData: Boat[]
  replay: Replay
  controls: IControlsProvider
  selectedBoats: number[]
  notSelectedBoatsTrailAreVisible: boolean
  onClick: (info: PickInfo<Boat>) => void
  onHover: (info: PickInfo<Boat>) => void
}

const BoatTripLayer = (props: Props) => {
  const {
    boatsData,
    replay,
    controls,
    selectedBoats,
    notSelectedBoatsTrailAreVisible,
    onClick,
    onHover,
  } = props

  const getWidth = (b: Boat): number => {
    const noBoatSelected = selectedBoats.length === 0
    const boatIsSelected = selectedBoats.includes(b.shipNum)
    if (noBoatSelected) {
      return DEFAULT_TRIP_WIDTH
    } else if (boatIsSelected) {
      return SELECTED_TRIP_WIDTH
    } else {
      if (notSelectedBoatsTrailAreVisible) {
        return GHOST_TRIP_WIDTH
      } else {
        return HIDDEN_TRIP_WIDTH
      }
    }
  }

  return new TripsLayer<Boat>({
    id: 'boat-trip-layer',
    data: boatsData,
    pickable: true,
    fadeTrail: controls.trailLength !== replay.getReplayDurationSeconds(),
    getPath: (b: Boat) => b.path,
    getTimestamps: (b: Boat) =>
      b.timestamps.map((t) => t - replay.startTime.toSeconds()),
    getColor: (b: Boat) => getColor(b.shipNum, selectedBoats),
    getWidth: getWidth,
    updateTriggers: {
      getWidth: [selectedBoats, notSelectedBoatsTrailAreVisible],
      getColor: [selectedBoats],
    },
    onClick: onClick,
    onHover: onHover,
    widthUnits: 'pixels',
    opacity: 0.8,
    capRounded: true,
    jointRounded: true,
    trailLength: controls.trailLength,
    currentTime: controls.currentTime - replay.startTime.toSeconds(),
  })
}

export default BoatTripLayer
