import React, { FunctionComponent, useEffect, useState } from 'react'

import App from './app'
import Boat from '@view_models/boat'
import { DialogContentText } from '@material-ui/core'
import Loading from '@components/loading'
import Modal from '@components/modal'
import ModalMapBackground from '@components/modal_map_background'
import Replay from '@view_models/replay'
import { ThemeProvider } from '@contexts/theme_context'
import { configureAxios } from '@utils/axios'
import fetchData from '@api/data_fetcher'

const Fetching: FunctionComponent = () => {
  const [error, setError] = useState<string | null>(null)
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)
  const [data, setData] = useState<{ replay: Replay; boats: Boat[] }>(
    {} as { replay: Replay; boats: Boat[] },
  )
  const [refreshIsPending, setRefreshIsPending] = useState<boolean>(false)

  const updateData = () => {
    setRefreshIsPending(true)
    fetchData()
      .then((fetchedData) => {
        setData(fetchedData)
      })
      .catch((resultError) => setError(resultError))
      .finally(() => {
        setRefreshIsPending(false)
        setHasLoaded(true)
      })
  }

  useEffect(() => {
    configureAxios()
    updateData()
  }, [])

  let content

  if (error) {
    content = (
      <>
        <ModalMapBackground />
        <Modal
          isVisible={error !== null}
          modal={{
            title: null,
            body: (
              <DialogContentText>
                {error ?? 'Erreur inconnue.'}
              </DialogContentText>
            ),
          }}
        />
      </>
    )
  } else if (hasLoaded) {
    content = (
      <App
        boats={data.boats}
        replay={data.replay}
        refreshData={updateData}
        refreshIsPending={refreshIsPending}
      />
    )
  } else {
    content = <Loading />
  }

  return <ThemeProvider>{content}</ThemeProvider>
}

export default Fetching
