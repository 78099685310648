import Boat from '@view_models/boat'
import Replay from '@view_models/replay'
import getOuting from './outing'
import getRace from './race'
import getTracker from './tracker'
import getTransistor from './transistor'

const fetchData = (): Promise<{ replay: Replay; boats: Boat[] }> => {
  const raceUuid = new URLSearchParams(window.location.search).get('race')
  const boatUuid = new URLSearchParams(window.location.search).get('boat')
  const deviceId = new URLSearchParams(window.location.search).get('tracker')
  const transitorDeviceId = new URLSearchParams(window.location.search).get(
    'transistor',
  )
  const start = new URLSearchParams(window.location.search).get('start')
  const finish = new URLSearchParams(window.location.search).get('finish')

  if (raceUuid) {
    return getRace(raceUuid)
  } else if (boatUuid) {
    return getOuting(boatUuid)
  } else if (deviceId) {
    return getTracker(deviceId)
  } else if (transitorDeviceId) {
    return getTransistor(transitorDeviceId, start, finish)
  } else {
    return Promise.reject('Missing boat or race parameter.')
  }
}

export default fetchData
