import {
  AttributionControl,
  MapContext,
  NavigationControl,
  StaticMap,
} from 'react-map-gl'
import { Paper, Tooltip } from '@material-ui/core'
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import ViewState, { viewStatesAreEquals } from '@view_models/view_state'
import {
  compassSvgIcon,
  zoomInSvgIcon,
  zoomOutSvgIcon,
} from '@assets/navigation_svg_icons'

import BoatDisplayContext from '@contexts/boat_display_context'
import ControlsContext from '@contexts/controls_context'
import DeckGL from '@deck.gl/react'
import ErrorContext from '@contexts/error_context'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import Hover from './hover'
import MarkerDetailList from '@components/marker_detail'
import ModalContext from '@contexts/modal_context'
import ReplayContext from '@contexts/replay_context'
import ShareDialogContent from './share_dialog_content'
import ShareIcon from '@material-ui/icons/Share'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import { TOP_BAR_HEIGHT } from '@utils/consts'
import ThemeContext from '@contexts/theme_context'
import { UndefinedError } from '@errors/default_error'
import clsx from 'clsx'
import { useCookies } from 'react-cookie'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigation: {
      position: 'absolute',
      top: 10,
      right: 10,
      boxShadow: theme.shadows[6],
      '& > div': {
        backgroundColor: theme.palette.background.paper,
      },
      '& > div > button > span': {
        backgroundColor: theme.palette.text.primary,
        backgroundImage: 'none !important',
      },
      '& > div > button:nth-child(1)': {
        maskImage: zoomInSvgIcon,
      },
      '& > div > button:nth-child(2)': {
        maskImage: zoomOutSvgIcon,
      },
      '& > div > button:nth-child(3)': {
        maskImage: compassSvgIcon,
      },
    },
    attribution: {
      right: 0,
      bottom: 0,
      fontFamily: 'Roboto',
      fontSize: 'small',
    },
    actionButtonsContainer: {
      color: theme.palette.text.primary,
      position: 'absolute',
      width: 29,
      top: 107 + TOP_BAR_HEIGHT,
      right: 10,
      boxShadow: theme.shadows[6],
      cursor: 'pointer',
    },
    actionButtonsContainerShadow: {
      boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
      '& > *:not(:first-child)': {
        borderTop: '1px solid #fff',
      },
    },
    actionButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 29,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    selectedActionButton: {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.text.primary,
    },
  }),
)

interface DeckGlViewStateProps {
  viewState: any
  interactionState: {
    inTransition?: boolean
    isDragging?: boolean
    isPanning?: boolean
    isRotating?: boolean
    isZooming?: boolean
  }
  oldViewState: any
}

const Map: FunctionComponent = () => {
  const modal = useContext(ModalContext)
  const controls = useContext(ControlsContext)
  const theme = useContext(ThemeContext)
  const replay = useContext(ReplayContext)
  const boatDisplay = useContext(BoatDisplayContext)
  const errorContext = useContext(ErrorContext)
  const [cookies, setCookie] = useCookies([replay.uuid])

  const [animation] = useState({ id: 0 })

  useEffect(() => {
    const onNoCookiedViewState = () => {
      boatDisplay.setViewState(replay.viewState)
    }

    const cookiedViewState = cookies[replay.uuid]
    if (cookiedViewState != undefined) {
      try {
        const newViewState = new ViewState(
          cookiedViewState.longitude,
          cookiedViewState.latitude,
          cookiedViewState.zoom,
        ).toDeckGlViewState()
        boatDisplay.setViewState(newViewState)
      } catch (error) {
        if (error instanceof UndefinedError) {
          onNoCookiedViewState()
        } else {
          throw error
        }
      }
    } else {
      onNoCookiedViewState()
    }
  }, [])

  const zoomToRaceArea = (): void => {
    boatDisplay.followsFleet && boatDisplay.toggleFollowFleet()
    boatDisplay.setViewState(replay.viewState)
  }

  const shouldDisplayCenterButton = useMemo(
    () => !viewStatesAreEquals(boatDisplay.viewState, replay.viewState),
    [boatDisplay.viewState],
  )

  const onViewStateChange = (args: DeckGlViewStateProps) => {
    try {
      const newViewState = new ViewState(
        args.viewState.longitude,
        args.viewState.latitude,
        args.viewState.zoom,
      ).toDeckGlViewState()
      boatDisplay.setViewState(newViewState)
      boatDisplay.setDeckViewState(args.viewState)
      setCookie(replay.uuid, newViewState)
    } catch (error) {
      if (!(error instanceof UndefinedError)) {
        throw error
      }
    }
  }

  const getCursor = (isDragging: boolean): string => {
    if (isDragging) {
      return 'grabbing'
    } else if (boatDisplay.hoverInfo != undefined) {
      return 'pointer'
    } else if (boatDisplay.hoveredBoatMarker?.object != undefined) {
      return 'pointer'
    } else {
      return 'grab'
    }
  }

  const animate = () => {
    if (controls.playback && !controls.isDraggingTimeline) {
      const nextTime = controls.currentTime + controls.playbackSpeed
      if (nextTime > replay.endTime.toSeconds()) {
        controls.setCurrentTime(replay.startTime.toSeconds())
      } else {
        controls.setCurrentTime(nextTime)
      }
      animation.id = window.requestAnimationFrame(animate)
    } else {
      window.cancelAnimationFrame(animation.id)
    }
  }

  useEffect(() => {
    animation.id = window.requestAnimationFrame(animate)
    return () => window.cancelAnimationFrame(animation.id)
  }, [animation.id, controls.playback, controls.isDraggingTimeline])

  const classes = useStyles()

  return (
    <Fragment>
      <DeckGL
        height={window.innerHeight - TOP_BAR_HEIGHT}
        style={{ marginTop: TOP_BAR_HEIGHT }}
        layers={boatDisplay.layers}
        viewState={boatDisplay.viewState}
        controller={true}
        pickingRadius={15}
        ContextProvider={MapContext.Provider}
        onViewStateChange={onViewStateChange}
        getCursor={({ isDragging }) => getCursor(isDragging)}
        onError={(error) =>
          errorContext.setError(`${error.name}
  ${error.message}
  ${error.stack}`)
        }
      >
        <StaticMap
          attributionControl={false}
          reuseMaps
          mapStyle={theme.mapStyle}
          mapboxApiAccessToken={process.env.MAPBOX_API_ACCESS_TOKEN}
          preventStyleDiffing={true}
        />

        <NavigationControl className={classes.navigation} />
        <AttributionControl compact={false} className={classes.attribution} />

        <Hover
          isVisible={boatDisplay.hoverInfo != undefined}
          hoverInfo={boatDisplay.hoverInfo!}
        />
      </DeckGL>
      <Paper className={classes.actionButtonsContainer}>
        <div className={classes.actionButtonsContainerShadow}>
          {shouldDisplayCenterButton && (
            <Tooltip title="Voir toutes les traces" placement="left">
              <div className={classes.actionButton} onClick={zoomToRaceArea}>
                <FullscreenIcon />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Centrer sur la flotte" placement="left">
            <div
              className={clsx(
                classes.actionButton,
                boatDisplay.followsFleet && classes.selectedActionButton,
              )}
              onClick={boatDisplay.toggleFollowFleet}
            >
              <GpsFixedIcon fontSize="small" />
            </div>
          </Tooltip>
          <Tooltip title="Trace des bateaux non sélectionnés" placement="left">
            <div
              className={clsx(
                classes.actionButton,
                boatDisplay.notSelectedBoatsTrailAreVisible &&
                  classes.selectedActionButton,
              )}
              onClick={boatDisplay.toggleNotSelectedBoatsTrailAreVisible}
            >
              <ShowChartIcon fontSize="small" />
            </div>
          </Tooltip>
          <Tooltip title="Partager" placement="left">
            <div
              className={clsx(classes.actionButton)}
              onClick={() => {
                modal.setModal({
                  title: 'Partager',
                  body: <ShareDialogContent />,
                })
                modal.setIsVisible(true)
              }}
            >
              <ShareIcon fontSize="small" />
            </div>
          </Tooltip>
        </div>
      </Paper>
      <MarkerDetailList
        boatsMarker={boatDisplay.boatsMarkerDetail}
        onClose={boatDisplay.unselectBoat}
      />
    </Fragment>
  )
}

export default Map
