import { GHOST_TRAIL_COLOR, getColorArrayFromId } from './colors'

import { RGBAColor } from '@deck.gl/core'

export const getColor = (
  shipNum: number,
  selectedBoats: number[],
): RGBAColor => {
  const boatIsSelected =
    selectedBoats.length === 0 || selectedBoats.includes(shipNum)
  if (boatIsSelected) {
    return getColorArrayFromId(shipNum)
  } else {
    return GHOST_TRAIL_COLOR
  }
}

const GHOST_TRAIL_WIDTH = 2
const DEFAULT_TRAIL_WIDTH = 4
const SELECTED_TRAIL_WIDTH = 4

export const getWidth = (shipNum: number, selectedBoats: number[]): number => {
  const noBoatSelected = selectedBoats.length === 0
  const boatIsSelected = selectedBoats.includes(shipNum)
  if (noBoatSelected) {
    return DEFAULT_TRAIL_WIDTH
  } else if (boatIsSelected) {
    return SELECTED_TRAIL_WIDTH
  } else {
    return GHOST_TRAIL_WIDTH
  }
}
