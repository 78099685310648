import React, { FunctionComponent } from 'react'

import Boat from '@view_models/boat'
import { BoatDisplayProvider } from '@contexts/boat_display_context'
import { BoatsProvider } from '@contexts/boats_context'
import Clock from '@components/clock'
import Controls from '@components/controls'
import { ControlsProvider } from '@contexts/controls_context'
import { ErrorProvider } from '@contexts/error_context'
import Map from '@components/map'
import { ModalProvider } from '@contexts/modal_context'
import Replay from '@view_models/replay'
import { ReplayProvider } from '@contexts/replay_context'
import TopBar from '@components/top_bar'

interface Props {
  replay: Replay
  boats: Boat[]
  refreshData: () => void
  refreshIsPending: boolean
}

const App: FunctionComponent<Props> = ({
  replay,
  boats,
  refreshData,
  refreshIsPending,
}) => {
  return (
    <ModalProvider>
      <ErrorProvider>
        <ReplayProvider replay={replay}>
          <BoatsProvider boats={boats}>
            <ControlsProvider>
              <BoatDisplayProvider>
                <Map />
                <TopBar />
                <Clock
                  refreshData={refreshData}
                  refreshIsPending={refreshIsPending}
                />
                <Controls />
              </BoatDisplayProvider>
            </ControlsProvider>
          </BoatsProvider>
        </ReplayProvider>
      </ErrorProvider>
    </ModalProvider>
  )
}

export default App
