import { Divider, IconButton, Link, Paper, Typography } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { BoatMarker } from '@view_models/boat'
import CloseIcon from '@material-ui/icons/Close'
import { DateTime } from 'luxon'
import fontColorContrast from 'font-color-contrast'
import { getColorFromId } from '@utils/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listRoot: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      bottom: 80,
      left: 10,
    },
    tooltipRoot: {
      marginTop: 10,
      maxWidth: window.innerWidth - 20,
    },
    verticalDivider: {
      alignSelf: 'stretch',
      height: 'auto',
      marginTop: 5,
      marginBottom: 5,
    },
    markerDetailTitle: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      flex: 1,
    },
    iconButton: {
      padding: 2,
    },
    markerHeader: {
      paddingLeft: 10,
      paddingRight: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    markerHeaderLink: {
      flex: 1,
      overflow: 'hidden',
    },
  }),
)

interface ListProps {
  boatsMarker: BoatMarker[]
  onClose: (shipNum: number) => void
}

const MarkerDetailList: FunctionComponent<ListProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.listRoot}>
      {props.boatsMarker.map((marker) => (
        <MarkerDetail
          key={marker.shipNum}
          boatMarker={marker}
          onClose={() => props.onClose(marker.shipNum)}
        />
      ))}
    </div>
  )
}

interface ItemProps {
  boatMarker: BoatMarker
  onClose: () => void
}

const MarkerDetail: FunctionComponent<ItemProps> = (props) => {
  const { boatMarker, onClose } = props

  const classes = useStyles()

  const skipperName = boatMarker.skipperName
    ? ` - ${boatMarker.skipperName}`
    : ''
  const boatTitle = `${boatMarker.name.toUpperCase()}${skipperName}`

  return (
    <Paper className={classes.tooltipRoot}>
      <div
        className={classes.markerHeader}
        style={{
          backgroundColor: getColorFromId(boatMarker.shipNum),
          color: fontColorContrast(getColorFromId(boatMarker.shipNum)),
        }}
      >
        <Link
          className={classes.markerHeaderLink}
          color="inherit"
          href={boatMarker.boatLink ?? undefined}
        >
          <Typography
            className={classes.markerDetailTitle}
            variant="subtitle1"
            noWrap
          >
            {boatTitle}
          </Typography>
        </Link>
        <IconButton
          className={classes.iconButton}
          aria-label="menu"
          onClick={onClose}
        >
          <CloseIcon
            htmlColor={fontColorContrast(getColorFromId(boatMarker.shipNum))}
          />
        </IconButton>
      </div>
      <div
        style={{
          display: 'block',
          overflow: 'hidden',
          marginTop: 2,
          marginBottom: 2,
          height: 40,
          lineHeight: 40,
        }}
      >
        <MarkerDetailAttribute
          title="cog"
          value={boatMarker.markerInfo.cog}
          unit="deg"
        />
        <MarkerDetailAttribute
          title="sog"
          value={boatMarker.markerInfo.sog}
          valueFractionDigits={1}
          unit="kts"
        />
        <MarkerDetailAttribute
          title="sog max"
          value={boatMarker.markerInfo.sogMax}
          valueFractionDigits={1}
          unit="kts"
        />
        <MarkerDetailAttribute
          title="trip"
          value={boatMarker.markerInfo.trip}
          valueFractionDigits={1}
          unit="nm"
        />
        <MarkerDetailAttribute
          title="tws"
          value={boatMarker.markerInfo.tws}
          valueFractionDigits={1}
          unit="kts"
        />
        <MarkerDetailAttribute
          title="tws max"
          value={boatMarker.markerInfo.twsMax}
          valueFractionDigits={1}
          unit="kts"
        />
        <MarkerDetailAttribute
          title="twd"
          value={boatMarker.markerInfo.twd}
          unit="deg"
        />
        <MarkerDetailAttribute
          title="twa"
          value={boatMarker.markerInfo.twa}
          unit="deg"
        />
      </div>
      <Divider />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <Typography
          variant="caption"
          color="textSecondary"
          align="center"
          noWrap
        >
          {DateTime.fromSeconds(props.boatMarker.timestamp).toLocaleString(
            DateTime.DATETIME_FULL_WITH_SECONDS,
          )}
        </Typography>
        <div style={{ flex: 1, width: 5 }} />
        <Typography variant="caption" color="textSecondary" align="center">
          {boatMarker.markerInfo.trackerType}
        </Typography>
      </div>
    </Paper>
  )
}

interface ItemAttributeProps {
  title: string
  value: number | null
  valueFractionDigits?: number
  unit: string
}

const MarkerDetailAttribute: FunctionComponent<ItemAttributeProps> = (
  props,
) => {
  const { title, value, valueFractionDigits = 0, unit } = props

  return (
    <div
      style={{
        float: 'left',
        height: 40,
        marginLeft: 10,
        marginRight: 10,
      }}
    >
      <Typography style={{ opacity: 0.6, fontSize: '0.75rem' }}>
        {title}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'baseline',
        }}
      >
        <Typography variant="subtitle2" noWrap>
          {value?.toFixed(valueFractionDigits) ?? '-'}
        </Typography>
        <Typography style={{ marginLeft: 4 }} variant="caption" noWrap>
          {unit}
        </Typography>
      </div>
    </div>
  )
}

export default MarkerDetailList
