export class SailCloudError extends Error {
  constructor(msg: string) {
    super(msg)
    Object.setPrototypeOf(this, SailCloudError.prototype)
  }
}

export class UndefinedError extends SailCloudError {
  constructor(msg: string) {
    super(msg)
    Object.setPrototypeOf(this, UndefinedError.prototype)
  }
}
