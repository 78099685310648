import { BitmapLayer } from '@deck.gl/layers'
import { TileLayer } from '@deck.gl/geo-layers'

interface Props {
  visible: boolean
}

const SeaMarksLayer = (props: Props) => {
  const { visible } = props

  return new TileLayer({
    id: 'sea-marks-layer',
    data: 'https://t1.openseamap.org/seamark/{z}/{x}/{y}.png',
    visible: visible,
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
    renderSubLayers: (innerProps: any) => {
      const {
        bbox: { west, south, east, north },
      } = innerProps.tile
      return new BitmapLayer(innerProps, {
        data: null,
        image: innerProps.data,
        bounds: [west, south, east, north],
      })
    },
  })
}

export default SeaMarksLayer
