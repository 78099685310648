import React, { FunctionComponent, useContext } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import ControlsContext from '@contexts/controls_context'
import IconButton from '@material-ui/core/IconButton'
import Logo from '@assets/sailtrack.svg'
import ReplayContext from '@contexts/replay_context'
import SettingsIcon from '@material-ui/icons/Settings'
import { TOP_BAR_HEIGHT } from '@utils/consts'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      boxShadow: theme.shadows[6],
      backgroundColor: theme.palette.background.default,
      paddingRight: 0,
      paddingLeft: '2%',
      width: '98%',
    },
    title: {
      flexGrow: 1,
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
      textDecoration: 'none',
      marginLeft: 20,
    },
    leftIcon: {
      width: TOP_BAR_HEIGHT - 12,
      height: TOP_BAR_HEIGHT - 12,
    },
  }),
)

const TopBar: FunctionComponent = () => {
  const controls = useContext(ControlsContext)
  const replay = useContext(ReplayContext)

  const classes = useStyles()

  return (
    <AppBar position="static">
      <div>
        <Toolbar className={classes.toolbar} variant="dense">
          <a href="https://sail.cloud">
            <img
              className={classes.leftIcon}
              src={Logo}
              alt="SAIL.cloud Logo"
            />
          </a>
          <a
            className={classes.title}
            href={replay.link ? replay.link : undefined}
          >
            <Typography variant="h6" noWrap>
              {replay.title || 'Replay'}
            </Typography>
            <Typography variant="subtitle1" noWrap>
              {replay.subtitle || '-'}
            </Typography>
          </a>
          <IconButton aria-label="menu" onClick={controls.openDrawer}>
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </div>
    </AppBar>
  )
}

export default TopBar
