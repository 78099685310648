import {
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { FunctionComponent } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

export interface ModalType {
  title: string | null
  body: JSX.Element | null
}

interface ModalProps {
  modal: ModalType
  isVisible: boolean
  setIsVisible?: (shouldBeVisible: boolean) => void
}

interface DialogTitleProps {
  onClose: () => void
}

const styles = (theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle: FunctionComponent<DialogTitleProps> = withStyles(styles)(
  (props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  },
)

const Modal: FunctionComponent<ModalProps> = ({
  modal,
  isVisible,
  setIsVisible = () => {},
}) => {
  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {modal.title && (
        <DialogTitle onClose={() => setIsVisible(false)}>
          {modal.title}
        </DialogTitle>
      )}
      {modal.body && <DialogContent>{modal.body}</DialogContent>}
    </Dialog>
  )
}

export default Modal
