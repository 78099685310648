import React, { FunctionComponent } from 'react'
import { Slider, Theme, withStyles } from '@material-ui/core'

import { COLORS } from '@utils/colors'

const CustomSlider = withStyles((theme: Theme) => {
  return {
    root: {
      color: COLORS.secondary,
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: COLORS.primary,
      border: '2px solid #fff',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 10,
      borderRadius: 4,
    },
    rail: {
      boxShadow: theme.shadows[6],
      height: 10,
      borderRadius: 4,
      backgroundColor: '#ddd',
    },
    mark: {
      backgroundColor: '#ddd',
      height: 10,
      width: 1,
    },
    markActive: {
      opacity: 1,
      backgroundColor: COLORS.primaryDark,
    },
  }
})(Slider)

interface TimelineSliderProps {
  max: number
  min: number
  onChange: (value: number) => void
  onChangeCommitted: () => void
  value: number
}

const TimelineSlider: FunctionComponent<TimelineSliderProps> = (
  props: TimelineSliderProps,
) => {
  const { max, min, onChange, onChangeCommitted, value } = props

  return (
    <CustomSlider
      min={min}
      max={max}
      value={value}
      aria-label="custom slider"
      onChange={(_, newValue) => onChange(newValue as number)}
      onChangeCommitted={onChangeCommitted}
    />
  )
}
export default TimelineSlider
