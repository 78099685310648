import Boat, { MarkerInfo } from '@view_models/boat'

import { DateTime } from 'luxon'
import { MarkerInfoDTO } from './boat_dto'
import RectangleCoordinates from '@view_models/rectangle_coordinates'
import Replay from '@view_models/replay'

export default interface TrackerDTO {
  ship_num: number
  name: string
  skipper_name: string
  view_state: RectangleCoordinates | null
  path: [number, number][]
  timestamps: number[]
  marker_info: MarkerInfoDTO[]
}

export const trackerToReplay = (
  tracker: TrackerDTO,
  deviceId: string,
): Replay => {
  const viewState = tracker.view_state
    ? tracker.view_state
    : ([
        tracker.path[0],
        tracker.path[tracker.path.length - 1],
      ] as RectangleCoordinates)
  const lastTimestamp = DateTime.fromSeconds(
    tracker.timestamps[tracker.timestamps.length - 1],
  )
  const firstTimestamp = DateTime.fromSeconds(tracker.timestamps[0])

  const numberOfDaysInTracking: string = Number.isNaN(
    Math.round(
      Math.abs(
        firstTimestamp.diff(lastTimestamp, 'days').toObject().days ?? NaN,
      ),
    ),
  )
    ? ''
    : `${Math.round(
        Math.abs(
          firstTimestamp.diff(lastTimestamp, 'days').toObject().days ?? NaN,
        ),
      )}`

  // const subtitle =
  return new Replay({
    id: 0,
    uuid: deviceId,
    startTime: firstTimestamp,
    endTime: lastTimestamp,
    name: tracker.name,
    banner: tracker.name,
    viewState: viewState,
    title: tracker.name,
    subtitle: tracker?.skipper_name
      ? tracker.skipper_name
      : `Positions sur les ${numberOfDaysInTracking} dernier(s) jour(s)`,
    link: null,
  })
}

export const trackerToBoat = (tracker: TrackerDTO): Boat => {
  return new Boat({
    ...tracker,
    boatLink: null,
    skipperName: tracker.skipper_name,
    markerInfo: tracker.marker_info.map((marker) =>
      MarkerInfo.fromMarkerInfoDTO(marker),
    ),
    shipNum: tracker.ship_num,
  })
}
