import { DYNAMIC_DRAWER_WIDTH, TOP_BAR_HEIGHT } from '@utils/consts'
import { Paper, Slider, Switch, Typography } from '@material-ui/core'
import React, { FunctionComponent, useContext } from 'react'
import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles'

import Brightness3Icon from '@material-ui/icons/Brightness3'
import { COLORS } from '@utils/colors'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ControlsContext from '@contexts/controls_context'
import Drawer from '@material-ui/core/Drawer'
import HeightIcon from '@material-ui/icons/Height'
import IconButton from '@material-ui/core/IconButton'
import LayersIcon from '@material-ui/icons/Layers'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import ReplayContext from '@contexts/replay_context'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import SpeedIcon from '@material-ui/icons/Speed'
import ThemeContext from '@contexts/theme_context'
import TimelineIcon from '@material-ui/icons/Timeline'
import clsx from 'clsx'
import { version } from '~/package.json'

const SCSwitch = withStyles({
  switchBase: {
    color: COLORS.primary,
    '&$checked': {
      color: COLORS.primary,
    },
    '&$checked + $track': {
      backgroundColor: COLORS.primary,
    },
  },
  checked: {},
  track: {},
})(Switch)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `${DYNAMIC_DRAWER_WIDTH}px`,
      borderLeft: `${theme.palette.divider} solid 2px`,
    },
    header: {
      width: '100%',
      height: TOP_BAR_HEIGHT,
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[3],
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
      marginBottom: 5,
    },
    sliders: {
      color: COLORS.primary,
    },
    title: {
      textAlign: 'center',
    },
    closeButton: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      margin: 0,
      left: 5,
    },
    optionItem: {
      margin: 5,
      marginBottom: 0,
      padding: 5,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icons: {
      marginRight: 10,
      color: theme.palette.text.hint,
    },
    settingName: {
      flex: 1,
    },
    widthIcon: {
      transform: 'rotate(90deg)',
    },
    version: {
      textAlign: 'center',
      marginTop: 'auto',
      verticalAlign: 'bottom',
    },
  }),
)

interface OptionDrawerProps {
  isDrawerOpen: boolean
  closeDrawer: () => void
}

const OptionDrawer: FunctionComponent<OptionDrawerProps> = (
  props: OptionDrawerProps,
) => {
  const classes = useStyles()

  const controls = useContext(ControlsContext)
  const replay = useContext(ReplayContext)
  const theme = useContext(ThemeContext)

  const raceDuration = replay.getReplayDurationSeconds()

  const MIN_TRAIL_LENGTH = raceDuration / 100
  const MAX_TRAIL_LENGTH = raceDuration
  const STEP_TRAIL_LENGTH = (MAX_TRAIL_LENGTH - MIN_TRAIL_LENGTH) / 280
  const MIN_PLAYBACK_SPEED = raceDuration / 100000
  const MAX_PLAYBACK_SPEED = raceDuration / 1000
  const STEP_PLAYBACK_SPEED = (MAX_PLAYBACK_SPEED - MIN_PLAYBACK_SPEED) / 280

  return (
    <Drawer
      elevation={0}
      BackdropProps={{ invisible: true }}
      anchor="right"
      open={props.isDrawerOpen}
      className={clsx(classes.drawer)}
      classes={{
        paper: clsx(classes.drawer),
      }}
      onClose={props.closeDrawer}
    >
      <div className={classes.header}>
        <IconButton
          className={classes.closeButton}
          onClick={controls.closeDrawer}
        >
          <ChevronRightIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6">
          Options
        </Typography>
      </div>
      <Paper className={classes.optionItem}>
        <HeightIcon
          fontSize="medium"
          className={`${classes.icons} ${classes.widthIcon}`}
        />
        <Typography className={classes.settingName}>Longueur Trace</Typography>
        <Slider
          className={classes.sliders}
          value={controls.trailLength}
          onChange={(_, value) => controls.setTrailLength(value as number)}
          valueLabelDisplay="off"
          step={STEP_TRAIL_LENGTH}
          min={MIN_TRAIL_LENGTH}
          max={MAX_TRAIL_LENGTH}
        />
      </Paper>
      <Paper className={classes.optionItem}>
        <SpeedIcon fontSize="medium" className={classes.icons} />
        <Typography className={classes.settingName}>Vitesse Replay</Typography>
        <Slider
          className={classes.sliders}
          value={controls.playbackSpeed}
          onChange={(_, value) => controls.setPlaybackSpeed(value as number)}
          valueLabelDisplay="off"
          step={STEP_PLAYBACK_SPEED}
          min={MIN_PLAYBACK_SPEED}
          max={MAX_PLAYBACK_SPEED}
        />
      </Paper>
      <Paper className={classes.optionItem}>
        <TimelineIcon fontSize="medium" className={classes.icons} />
        <Typography className={classes.settingName}>
          Positions Bateaux
        </Typography>
        <SCSwitch
          checked={controls.locationMarkerAreVisible}
          onChange={controls.toggleLocationMarker}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Paper>
      <Paper className={classes.optionItem}>
        <LocalOfferIcon fontSize="medium" className={classes.icons} />
        <Typography className={classes.settingName}>Nom Bateaux</Typography>
        <SCSwitch
          checked={controls.boatNameIsVisible}
          onChange={controls.toggleBoatName}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Paper>
      <Paper className={classes.optionItem}>
        <ShowChartIcon fontSize="medium" className={classes.icons} />
        <Typography className={classes.settingName}>Traces Bateaux</Typography>
        <SCSwitch
          checked={controls.boatPathIsVisible}
          onChange={controls.toggleBoatPath}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Paper>
      <Paper className={classes.optionItem}>
        <LayersIcon fontSize="medium" className={classes.icons} />
        <Typography className={classes.settingName}>
          Phares et Balises
        </Typography>
        <SCSwitch
          checked={controls.seaMarksAreVisible}
          onChange={controls.toggleSeaMarks}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Paper>
      <Paper className={classes.optionItem}>
        <Brightness3Icon fontSize="medium" className={classes.icons} />
        <Typography className={classes.settingName}>Mode Sombre</Typography>
        <SCSwitch
          checked={theme.isInDarkMode}
          onChange={theme.toggleTheme}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Paper>
      <Typography
        className={classes.version}
        variant="caption"
        color="textSecondary"
      >{`v${version}`}</Typography>
    </Drawer>
  )
}

export default OptionDrawer
