import Boat, { BoatMarker, BoatPath } from '@view_models/boat'
import {
  DARK_MODE_BOAT_TAG_COLOR,
  LIGHT_MODE_BOAT_TAG_COLOR,
} from '@utils/colors'
import Layer, { LayerProps } from '@deck.gl/core/lib/layer'
import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ViewState, { ViewStateObject } from '@view_models/view_state'

import BoatMarkersLayer from '@contexts/deck_gl_layers/boat_markers'
import BoatTagLayer from '@contexts/deck_gl_layers/boat_tag'
import BoatTrailLayer from '@contexts/deck_gl_layers/boat_trail'
import BoatTripLayer from '@contexts/deck_gl_layers/boat_trip'
import BoatsContext from '@contexts/boats_context'
import ControlsContext from '@contexts/controls_context'
import HoverViewModel from '@view_models/hover'
import { PickInfo } from 'deck.gl'
import ReplayContext from '@contexts/replay_context'
import SeaMarksLayer from '@contexts/deck_gl_layers/sea_marks'
import ThemeContext from '@contexts/theme_context'
import { WebMercatorViewport } from '@deck.gl/core'
import { isMobile } from 'react-device-detect'

const BoatDisplayContext = React.createContext<IBoatDisplayProvider>(
  {} as IBoatDisplayProvider,
)

export interface IBoatDisplayProvider {
  layers: Layer<any, LayerProps<any>>[]
  hoverInfo: HoverViewModel | null
  hoveredBoatMarker: PickInfo<BoatMarker> | null
  boatsMarkerDetail: BoatMarker[]
  viewState: ViewStateObject
  deckViewState: any
  followsFleet: boolean
  notSelectedBoatsTrailAreVisible: boolean
  setViewState: (viewState: ViewStateObject) => void
  setDeckViewState: (deckViewState: any) => void
  toggleFollowFleet: () => void
  toggleNotSelectedBoatsTrailAreVisible: () => void
  unselectBoat: (shipNum: number) => void
}

interface Props {
  children: ReactNode
}

const TIMELINE_MARKER_DISPLAY_OFFSET = 0.001

export const BoatDisplayProvider: FunctionComponent<Props> = (props) => {
  const controls = useContext(ControlsContext)
  const boatsData = useContext(BoatsContext)
  const replay = useContext(ReplayContext)
  const theme = useContext(ThemeContext)

  const selectSingleBoatByDefault = (): number[] => {
    const singleBoatReplay = boatsData.length === 1
    if (singleBoatReplay) {
      return [boatsData[0].shipNum]
    } else {
      return []
    }
  }

  const [selectedBoats, setSelectedBoats] = useState<number[]>(
    selectSingleBoatByDefault(),
  )
  const [hoverBoatData, setHoverBoatData] = useState<HoverViewModel | null>(
    null,
  )
  const [hoveredBoatMarker, hoverMarker] =
    useState<PickInfo<BoatMarker> | null>(null)
  const [viewState, setViewState] = useState<ViewStateObject>(
    {} as ViewStateObject,
  )
  const [deckViewState, setDeckViewState] = useState<any>({})
  const [followsFleet, setFollowsFleet] = useState(false)
  const [notSelectedBoatsTrailAreVisible, setNotSelectedBoatsTrailAreVisible] =
    useState(true)

  const toggleFollowFleet = () => setFollowsFleet(!followsFleet)
  const toggleNotSelectedBoatsTrailAreVisible = () =>
    setNotSelectedBoatsTrailAreVisible(!notSelectedBoatsTrailAreVisible)

  const locationMarkerData = useMemo(
    () =>
      boatsData
        .map((boat) => boat.toBoatMarkers())
        .flat()
        .filter(
          (boat) =>
            boat.timestamp < controls.currentTime &&
            boat.timestamp > controls.currentTime - controls.trailLength &&
            (selectedBoats.length === 0 ||
              selectedBoats.includes(boat.shipNum)),
        ),
    [controls.currentTime, controls.trailLength, selectedBoats],
  )

  const eachBoatLatestMarker = useMemo(
    () => boatsData.map((boat) => boat.getLastMarker(controls.currentTime)),
    [controls.currentTime],
  )

  useEffect(() => {
    const viewport = new WebMercatorViewport(deckViewState)

    const [minLon, maxLat] = viewport.unproject([0, 0])
    const [maxLon, minLat] = viewport.unproject([
      viewport.width,
      viewport.height,
    ])

    const visibleMarkers = boatsData
      .map((boat) => boat.toBoatMarkers())
      .flat()
      .filter(
        (marker) =>
          marker.coordinates[0] > minLon &&
          marker.coordinates[0] < maxLon &&
          marker.coordinates[1] > minLat &&
          marker.coordinates[1] < maxLat,
      )

    if (visibleMarkers.length > 10) {
      const minTimestamp = Math.min.apply(
        Math,
        visibleMarkers.map((marker) => {
          return marker.timestamp
        }),
      )

      const maxTimestamp = Math.max.apply(
        Math,
        visibleMarkers.map((marker) => {
          return marker.timestamp
        }),
      )

      controls.setVisibleTimelineStart(minTimestamp)
      controls.setVisibleTimelineEnd(maxTimestamp)
    }
  }, [viewState, controls.trailLength])

  useEffect(() => {
    if (!followsFleet) {
      return
    }

    const eachSelectedBoatLatestMarker = eachBoatLatestMarker.filter(
      (bm: BoatMarker) => selectedBoats.includes(bm.shipNum),
    )

    const fleet =
      eachSelectedBoatLatestMarker.length > 0
        ? eachSelectedBoatLatestMarker
        : eachBoatLatestMarker

    const maxLongitude = Math.max.apply(
      Math,
      fleet.map((marker) => {
        return marker.coordinates[0]
      }),
    )
    const minLongitude = Math.min.apply(
      Math,
      fleet.map((marker) => {
        return marker.coordinates[0]
      }),
    )
    const maxLatitude = Math.max.apply(
      Math,
      fleet.map((marker) => {
        return marker.coordinates[1]
      }),
    )
    const minLatitude = Math.min.apply(
      Math,
      fleet.map((marker) => {
        return marker.coordinates[1]
      }),
    )

    const newViewState = ViewState.fromRectangle([
      [maxLongitude, maxLatitude],
      [minLongitude, minLatitude],
    ] as [[number, number], [number, number]])

    if (viewState.zoom) {
      newViewState.zoom = viewState.zoom
    }

    // SHOULD NOT OVERRIDE DEFAULT VIEWSTATE
    setViewState(newViewState)
  }, [eachBoatLatestMarker, followsFleet])

  const boatsMarkerDetail = eachBoatLatestMarker.filter((marker) =>
    selectedBoats.includes(marker.shipNum),
  )

  const selectBoat = (shipNum: number, isAMarkerClick: boolean = false) => {
    const boatAlreadySelected = selectedBoats.includes(shipNum)
    if (boatAlreadySelected) {
      if (!isAMarkerClick) {
        unselectBoat(shipNum)
      }
    } else {
      if (isMobile) {
        setSelectedBoats([shipNum])
      } else {
        const updatedBoatsMarker = [...selectedBoats]
        updatedBoatsMarker.push(shipNum)

        setSelectedBoats(updatedBoatsMarker)
      }
    }
  }

  const unselectBoat = (shipNum: number) => {
    const filteredSelectedBoats = selectedBoats.filter(
      (selectedBoatMarker) => selectedBoatMarker !== shipNum,
    )
    setSelectedBoats(filteredSelectedBoats)
  }

  const onMarkerPress = (info: PickInfo<BoatMarker>) => {
    controls.playback && controls.togglePlayback()
    controls.setCurrentTime(
      info.object.timestamp + TIMELINE_MARKER_DISPLAY_OFFSET,
    )
    setHoverBoatData(null)
    selectBoat(info.object.shipNum, true)
    console.log(`coords`, info.object.coordinates)
  }

  const onMarkerHover = (info: PickInfo<BoatMarker>) => {
    if (info.object) {
      hoverMarker(info)
      setHoverBoatData(HoverViewModel.fromBoatMarkerPickInfo(info))
    } else {
      hoverMarker(null)
      setHoverBoatData(null)
    }
  }

  const layers = [
    SeaMarksLayer({ visible: controls.seaMarksAreVisible }),
    BoatTrailLayer({
      controls: controls,
      boatsData: boatsData,
      selectedBoats: selectedBoats,
      notSelectedBoatsTrailAreVisible: notSelectedBoatsTrailAreVisible,
      onClick: (info: PickInfo<BoatPath>) => selectBoat(info.object.shipNum),
      onHover: (info: PickInfo<BoatPath>) =>
        setHoverBoatData(HoverViewModel.fromBoatPickInfo(info)),
    }),
    BoatTripLayer({
      boatsData: boatsData,
      replay: replay,
      controls: controls,
      selectedBoats: selectedBoats,
      notSelectedBoatsTrailAreVisible: notSelectedBoatsTrailAreVisible,
      onClick: (info: PickInfo<Boat>) => selectBoat(info.object.shipNum),
      onHover: (info: PickInfo<Boat>) =>
        setHoverBoatData(HoverViewModel.fromBoatPickInfo(info)),
    }),
    BoatMarkersLayer({
      locationMarkerData: locationMarkerData,
      controls: controls,
      boatsMarker: selectedBoats,
      boatsMarkerDetail: boatsMarkerDetail,
      hoveredBoatMarker: hoveredBoatMarker,
      viewState: viewState,
      onHover: onMarkerHover,
      onClick: onMarkerPress,
    }),
    BoatTagLayer({
      eachBoatLatestMarker: eachBoatLatestMarker,
      controls: controls,
      selectedBoats: selectedBoats,
      textColor: theme.isInDarkMode
        ? DARK_MODE_BOAT_TAG_COLOR
        : LIGHT_MODE_BOAT_TAG_COLOR,
    }),
  ].flat()

  return (
    <BoatDisplayContext.Provider
      value={{
        layers: layers,
        hoverInfo: hoverBoatData,
        hoveredBoatMarker: hoveredBoatMarker,
        boatsMarkerDetail: boatsMarkerDetail,
        viewState: viewState,
        deckViewState: deckViewState,
        followsFleet: followsFleet,
        notSelectedBoatsTrailAreVisible: notSelectedBoatsTrailAreVisible,
        setViewState: setViewState,
        setDeckViewState: setDeckViewState,
        toggleFollowFleet: toggleFollowFleet,
        unselectBoat: unselectBoat,
        toggleNotSelectedBoatsTrailAreVisible:
          toggleNotSelectedBoatsTrailAreVisible,
      }}
    >
      {props.children}
    </BoatDisplayContext.Provider>
  )
}

export default BoatDisplayContext
