import { CircularProgress, Paper, Tooltip, Typography } from '@material-ui/core'
import {
  MuiThemeProvider,
  Theme,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles'
import React, { FunctionComponent, useContext } from 'react'

import { COLORS } from '@utils/colors'
import ControlsContext from '@contexts/controls_context'
import { DateTime } from 'luxon'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 'fit-content',
      position: 'absolute',
      left: '50%',
      transform: `translate(-50%, 10px)`,
      display: 'flex',
      flexDirection: 'row',
      boxShadow: theme.shadows[6],
      padding: 5,
      justifyContent: 'center',
      alignItems: 'center',
    },
    actionButton: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // height: 29,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    text: {
      paddingRight: 5,
    },
  }),
)

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
  },
})

interface Props {
  refreshData: () => void
  refreshIsPending: boolean
}

const Clock: FunctionComponent<Props> = ({ refreshData, refreshIsPending }) => {
  const controlsContext = useContext(ControlsContext)

  const timelineDateTime = DateTime.fromSeconds(
    controlsContext.currentTime,
  ).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)

  const classes = useStyles()

  return (
    <Paper className={classes.paper} elevation={3}>
      <Tooltip title="Date de la position la plus récente" placement="bottom">
        <Typography className={classes.text} variant="subtitle2">
          {timelineDateTime}
        </Typography>
      </Tooltip>
      <Tooltip title="Mettre à jour la position des bateaux" placement="bottom">
        {refreshIsPending ? (
          <MuiThemeProvider theme={theme}>
            <CircularProgress size={20} color="primary" />
          </MuiThemeProvider>
        ) : (
          <div className={classes.actionButton} onClick={refreshData}>
            <RefreshIcon fontSize="small" />
          </div>
        )}
      </Tooltip>
    </Paper>
  )
}

export default Clock
