import TrackerDTO from '../dto/tracker_dto'
import axios from 'axios'
import { stringifyError } from '@utils/axios'

const POS_MAPS_TRACKERS_URL = 'rpc/pos_maps_trackers'

export default class TrackerRepository {
  static get(deviceId: string): Promise<TrackerDTO> {
    return new Promise<TrackerDTO>((resolve, reject) => {
      axios
        .post<TrackerDTO[]>(POS_MAPS_TRACKERS_URL, {
          tracker_device_id: deviceId,
        })
        .then((request) => {
          const httpIsError = request.status >= 400
          if (httpIsError) {
            return reject(`Impossible d'accèder à l'API.
${request.status} : ${request.statusText}`)
          }

          const hasATracker = request.data && request.data.length > 0
          if (hasATracker) {
            resolve(request.data[0])
          } else {
            reject(
              "Aucune position pour ce tracker dans l'intervalle de temps.",
            )
          }
        })
        .catch((error) => reject(stringifyError(error)))
    })
  }
}
