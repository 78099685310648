import React, { FunctionComponent, ReactNode, useState } from 'react'

import Boat from '@view_models/boat'

const BoatsContext = React.createContext<Boat[]>([] as Boat[])

interface Props {
  children: ReactNode
  boats: Boat[]
}

export const BoatsProvider: FunctionComponent<Props> = (props: Props) => {
  const [boats] = useState<Boat[]>(props.boats)

  return (
    <BoatsContext.Provider value={boats}>
      {props.children}
    </BoatsContext.Provider>
  )
}

export default BoatsContext
